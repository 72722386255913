import React from 'react';
import { useUser, UserContext } from '../GoogleAuth';
import Menu from './Menu';
import AppContent from './Content';

export default function Layout(): JSX.Element {
    const user = useUser();

    if (user === null) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-grow" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <UserContext.Provider value={user}>
                <Menu user={user} />
                <div className="body AppContent d-flex flex-column">
                    <AppContent user={user} />
                </div>
            </UserContext.Provider>
        </>
    );
}
