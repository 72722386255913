import { useAxiosEx } from '../DataLoader';
import { MultiTrackingResponse } from '@bp/utils';

export function useDHLTracking(trackingId: string) {
    const url = `/api-proxy/api-dhl/shipments/${trackingId}/tracking`;
    return useAxiosEx<MultiTrackingResponse>(url);
}

export function useDHLTrackings(trackingId: string[]) {
    const url = `/api-proxy/api-dhl/tracking?trackingId=${trackingId}`;
    return useAxiosEx<MultiTrackingResponse>(url);
}
