/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as JiraHelpers from './jira-helpers';
import { DateTime } from 'luxon';
import { Tabulator } from 'react-tabulator\\lib\\types\\TabulatorTypes.d';
// import { reactFormatter } from 'react-tabulator';

/*
const createCellEl = (): any => {
    const el = document.createElement('div');
    el.style.height = '100%';
    return el;
};
*/

export function tabulatorFormatterItemName(cell: Tabulator.CellComponent, _formatterParams: any, _onRendered: any): any {
    if (cell == null || cell === undefined) return '';
    const rowData = cell.getRow().getData();

    cell?.getElement().classList.add('SAPItemName');

    let value = cell.getValue();
    if (rowData.LineText != null) {
        value = rowData.LineText;
        cell.getRow().getElement().classList.add('lineTypeText');
        return value;
    }

    try {
        if (cell?.getRow()?.getTreeChildren()?.length > 0) {
            cell.getElement().classList.add('lineTypeParent');
        }
    } catch (error) {}

    if (rowData.frozenFor !== undefined && rowData.frozenFor === 'Y') {
        cell.getRow().getElement().classList.add('lineTypeInactiveItem');
    }

    if (rowData.IsDeprecated !== undefined && rowData.IsDeprecated === 'Y') {
        cell.getRow().getElement().classList.add('lineTypeDeprecatedItem');
    }

    switch (rowData.TreeType) {
        case 'X': {
            if (rowData.LineType === 'S') {
                cell.getRow().getElement().classList.add('lineTypeSubTotal');
                return '';
            }
            if (rowData.LineType === 'T') {
                cell.getRow().getElement().classList.add('lineTypeText');
                return rowData.LineText;
            }
            break;
        }
    }
    switch (rowData.TreeType) {
        case 'S': {
            cell.getElement().classList.add('bomTypeSales');
            break;
        }
        case 'A': {
            cell.getElement().classList.add('bomTypeAssembly');
            break;
        }
        case 'P': {
            cell.getElement().classList.add('bomTypeProduction');
            break;
        }
        case 'T': {
            cell.getElement().classList.add('bomTypeTemplate');
            break;
        }
    }

    if (rowData.ItemNameOrig !== undefined && rowData.ItemNameOrig !== value && rowData.DocTreeType !== 'X') {
        cell.getElement().style.backgroundColor = 'lightyellow';
        const result = document.createElement('span');
        result.innerText = value;
        result.setAttribute('title', rowData.ItemNameOrig);
        return result;
    }

    if (rowData.Link != null && cell.getField() !== 'ItemCode') {
        value = `${value as string}<a style='float:right;padding-left:5px' href="${
            rowData.Link as string
        }" target="_blank"><i style="" class="bi bi-box-arrow-up-right">
            </i></a>`;
    }

    if (rowData.Is3DPrint === 'Y' && cell.getField() !== 'ItemCode') {
        value = `${value as string}<span style='float:right'>3D</span>`;
    }

    return value;
}

// eslint-disable-next-line no-unused-vars
export function tabulatorRowFormatterDocs(row: any): any {
    const data = row.getData();
    if (data.DocUpdateDate === null || timeSince(new Date(data.DocUpdateDate))._days < 4) {
        row.getElement().classList.add('rowUpdatedRecently');
    }
    if (data._CANCELED === 'Y' || data.DocStatusProductionOrder === 'L') {
        row.getElement().classList.add('sapDocStatusCanceled');
    }
    if (data.DocStatus === 'C' || data.DocStatusProductionOrder === 'C') {
        row.getElement().classList.add('sapDocStatusClosed');
    }
    if (data.DocNum === null) {
        row.getElement().classList.add('fst-italic');
    }
}

export function tabulatorRowFormatterAssets(row: any): any {
    const data = row.getData();
    if (data.RemovedDate != null) {
        row.getElement().classList.add('sapDocStatusCanceled');
    }
}

function timeSince(when: Date): any {
    // this ignores months
    const dateToday = new Date();
    const obj: any = {};
    obj._milliseconds = dateToday.getTime() - when.getTime();
    obj.milliseconds = obj._milliseconds % 1000;
    obj._seconds = (obj._milliseconds - obj.milliseconds) / 1000;
    obj.seconds = obj._seconds % 60;
    obj._minutes = (obj._seconds - obj.seconds) / 60;
    obj.minutes = obj._minutes % 60;
    obj._hours = (obj._minutes - obj.minutes) / 60;
    obj.hours = obj._hours % 24;
    obj._days = (obj._hours - obj.hours) / 24;
    obj.days = obj._days % 365;
    obj.years = (obj._days - obj.days) / 365;
    return obj;
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterDocMessage(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value === '') return value;

    let result = "<div style='text-align:center'>";
    for (const ch of value) {
        switch (ch) {
            case 'A': {
                result += '<span class="badge bg-danger"  style="font-size:9px;">no attachment</span>';
                break;
            }
            case 'B': {
                result += '<span class="badge bg-success" style="font-size:9px;">In Bank</span>';
                break;
            }
            case 'P': {
                result += '<span class="badge bg-warning" style="font-size:9px;">Adv Pay</span>';
                break;
            }
        }
    }
    result += '</div>';
    return result;
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterProgressEx(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value === null) return null;

    let legend = value;
    if (typeof formatterParams?.legend === 'function') {
        legend = formatterParams?.legend(value);
    }

    let color = 'black';
    if (typeof formatterParams?.color === 'function') {
        color = formatterParams?.color(value);
    }
    const html = `<div style="position: relative; width: 100%; height: 100%;">
    <div data-max="100" data-min="0" style="display: inline-block; width: ${value as number}%; background-color: ${color}; height: 100%;"></div>
    <div style="position: absolute; top: 0px; left: 0px; text-align: center; vertical-align: middle; width: 100%; color: rgb(0, 0, 0);">${legend as string}</div>
    </div>`;

    return html;
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormaterJrnlMemo(cell: any, _formatterParams: any, _onRendered: any): any {
    const rowData = cell.getData();

    const ageCreated = timeSince(new Date(rowData.DocDate))._days;
    const ageUpdated = timeSince(new Date(rowData.DocUpdateDate))._days;

    let result = "<div style='text-align:left'><div style='float:right;font-size:9px;'>";
    if (ageCreated < 4) {
        result += '<span class="badge bg-warning" style="background-color: #ffc107;font-size:9px;margin:1px">new</span>';
    }
    if (ageUpdated < 4) {
        result += '<span class="badge bg-warning" style="background-color: #ffc107;font-size:9px;margin:1px">chg</span>';
    }
    result += '</div>'; // <div style='display:inline;float:left;font-size:9px;'>";

    let attachIcon = '&nbsp;';
    if (rowData.AttachExists === 'true') {
        attachIcon = '&#128206;';
    }
    result += "<span class='glyphicon'>" + attachIcon + '</span>';
    // result += "</div>"
    result += cell.getValue() as string;

    result += '</div>';
    return result;
}

// eslint-disable-next-line no-unused-vars
export function formatterDateExtended(cell: any, formatterParams: any, _onRendered: any): any {
    const cellValue = cell.getValue();
    if (cellValue == null) return '';

    const cellDate = new Date(cell.getValue());
    const ageDays = timeSince(cellDate)._days;
    // future dates are positive

    let dateFormat = 'D';

    let suppressFormating = false;

    if (formatterParams != null) {
        const rowData = cell.getRow().getData();
        if (formatterParams.suppressFormating !== undefined) {
            suppressFormating = formatterParams.suppressFormating(cellValue, rowData);
        }

        if (formatterParams.dateFormat != null) {
            dateFormat = formatterParams.dateFormat;
        }
        /*
        if (formatterParams.showAge !== undefined && ageDays !== 0) {
            content = <span className="dateAgeOnLeft">ageDays</span>;
        }
        */

        if (!suppressFormating && formatterParams.highlightDueIn > 0 && formatterParams.highlightDueInStyle !== undefined) {
            if (ageDays < 0 && Math.abs(ageDays) <= formatterParams.highlightDueIn) {
                cell.getElement().classList.add(formatterParams.highlightDueInStyle);
            }
        }

        if (!suppressFormating && formatterParams.highlightPastStyle !== undefined) {
            if (ageDays > 0) {
                cell.getElement().classList.add(formatterParams.highlightPastStyle);
            }
        }

        if (!suppressFormating && formatterParams.highlightDateConfirmedStyle !== undefined) {
            if (rowData._DocDueDateConfirmed === 'Y') {
                cell.getElement().classList.add(formatterParams.highlightDateConfirmedStyle);
            }
        }
    }

    // eslint-disable-next-line no-undef
    const date = DateTime.fromJSDate(cellDate);
    const dateString = date?.toFormat(dateFormat);
    const content = (
        <React.Fragment>
            {!suppressFormating && <span className="dateAgeOnLeft">{ageDays}</span>}
            {dateString}
        </React.Fragment>
    );

    const root = createRoot(cell.getElement());
    root.render(content);

    // return el;
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterTreeType(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    const rowData = cell.getRow().getData();
    if (rowData.ItemCode == null) return '';

    switch (value) {
        case 'S': {
            cell.getElement().classList.add('bomTypeSales');
            return 'Sales';
        }
        case 'A': {
            cell.getElement().classList.add('bomTypeAssembly');
            return 'Assembly';
        }
        case 'P': {
            cell.getElement().classList.add('bomTypeProduction');
            return 'Production';
        }
        case 'T': {
            cell.getElement().classList.add('bomTypeTemplate');
            return 'Template';
        }
    }
}

export function tabulatorFormatterApprovalStatus(cell: any, _formatterParams: any, _onRendered: any): any {
    if (cell === undefined) return;
    const value = cell.getValue();
    switch (value) {
        case 'W': {
            cell.getElement().classList.add('sapApprovalStatusWaiting');
            return 'Waiting';
        }
        case 'Y': {
            cell.getElement().classList.add('sapApprovalStatusApproved');
            return 'Approved';
        }
        case 'N': {
            cell.getElement().classList.add('sapApprovalStatusDenied');
            return 'Denied';
        }
        default:
            return value;
    }
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterDocStatus(cell: any, _formatterParams: any, _onRendered: any): any {
    if (cell === undefined) return;

    const rowData = cell.getRow().getData();
    const iC = rowData._CANCELED;
    switch (iC) {
        case 'Y': {
            cell.getElement().classList.add('sapDocStatusCanceled');
            return 'Canceled';
        }
    }

    const value = cell.getValue();
    switch (value) {
        case 'O': {
            cell.getElement().classList.add('sapDocStatusOpen');
            return 'Open';
        }
        case 'C': {
            cell.getElement().classList.add('sapDocStatusClosed');
            return 'Closed';
        }
        case 'W': {
            cell.getElement().classList.add('sapDocStatusAppWaiting');
            return 'Approval';
        }
        default:
            return value;
    }
}

export function sapDocStatusProductionOrder(value: any, cell: any): any {
    switch (value) {
        case 'L': {
            cell?.getElement().classList.add('sapDocStatusClosed');
            return 'Closed';
        }
        case 'P': {
            cell?.getElement().classList.add('sapDocStatusPlanned');
            return 'Planned';
        }
        case 'R': {
            cell?.getElement().classList.add('sapDocStatusReleased');
            return 'Released';
        }
        default:
            return value;
    }
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterDocStatusProductionOrder(cell: any, _formatterParams: any, _onRendered: any): any {
    return sapDocStatusProductionOrder(cell.getValue(), cell);
}

// eslint-disable-next-line no-unused-vars
export function tabulatorFormatterDocType(cell: any, _formatterParams: any, _onRendered: any): any {
    let value = cell.getValue();
    if (typeof value === 'string') {
        value = parseInt(value);
    }
    switch (value) {
        case 1470000113:
            return 'Purchase Request';
        case 23:
            return 'Sales Quotation';
        case 14:
            return 'Credit Memo (A/R)';
        case 17:
            return 'Sales Order';
        case 18:
            return 'Purchase Invoice';
        case 19:
            return 'Credit Memo (A/P)';
        case 15:
            return 'Sales Delivery';
        case 22:
            return 'Purchase Order';
        case 204:
            return 'Purchase Advance';
        case 20:
            return 'Purchase Delivery';
        case 13:
            return 'Sales Invoice';
        case 203:
            return 'Sales Advance';
        case 202:
            return 'Production Order';
        case 67:
            return 'Inventory Transfer';
    }
    return value;
}

export function tabulatorFormatterJiraStatus(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    return JiraHelpers.GetJiraStatusSpan(value);
}

export function tabulatorformatterKeyCountHeader(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (!value) return '';
    let separator = '&nbsp;';
    if (formatterParams?.separator !== undefined) {
        separator = formatterParams?.separator;
    }
    let html = '<span style="display: table;margin: 0 auto;">';
    Object.keys(value).forEach((key) => {
        html += JiraHelpers.GetJiraStatusSpan(key, value[key], key) + separator;
    });
    html += '</span>';

    return html;
}

export function tabulatorformatterNeedsMaintenance(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    const status = cell.getRow().getData().status;
    if (status !== 'Delivered' && status !== 'COMMISSIONED') return;
    if (!value) return '';
    let html = '<span style="display: table;margin: 0 auto;">';
    html += '<span title="WM" class="statusBuble ' + (value.wm ? 'statusBuble_Red' : 'statusBuble_Grey') + '">WM</span>&nbsp;';
    html += '<span title="MM" class="statusBuble ' + (value.mm ? 'statusBuble_Red' : 'statusBuble_Grey') + '">MM</span>&nbsp;';
    html += '<span title="QM" class="statusBuble ' + (value.qm ? 'statusBuble_Red' : 'statusBuble_Grey') + '">QM</span>&nbsp;';
    html += '<span title="YM" class="statusBuble ' + (value.ym ? 'statusBuble_Red' : 'statusBuble_Grey') + '">YM</span>&nbsp;';
    html += '</span>';
    return html;
}

export function tabulatorformatterTachometer(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value == null) return '';
    const inKmStr = (value / 1000).toFixed(0);
    tabulatorformatterGreyIfOld(cell, formatterParams, _onRendered);
    return inKmStr.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ') + ' km';
}

export function tabulatorformatterDateAsAge(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value == null || value === '') return '';
    const sp = timeSince(new Date(value));
    if (sp.days < 1) {
        if (sp.hours < 1) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return sp.minutes + ' min';
        } else {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return sp.hours + ' hrs';
        }
    } else {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return sp.days + ' day';
    }
}

export function tabulatorformatterGroupHeaderStatusKeyCount(value: any, count: any, data: any, _group: any): any {
    const statusCount: any = {};
    data.forEach((i: any) => {
        if (statusCount[i.status] === undefined) statusCount[i.status] = 0;
        statusCount[i.status] = (statusCount[i.status] as number) + 1;
    });

    let html = '<span style="color:black">';
    html += `<span>${value as string}</span>`;
    Object.keys(statusCount).forEach((key) => {
        const per = Math.round((statusCount[key] / data.length) * 100);
        html += JiraHelpers.GetJiraStatusSpan(key, `${statusCount[key] as string} (${per}%)`, key, true) + '&nbsp;';
    });
    html += `<span>Total: ${data.length as number}</span>`;
    html += '</span>';

    return html;
}

export function tabulatorformatterGreyIfOld(cell: any, _formatterParams: any, _onRendered: any): any {
    const data = cell.getRow().getData();
    if (data?.auto_LastUpdated !== undefined) {
        const sp = timeSince(new Date(data?.auto_LastUpdated));
        if (sp.days > 10) {
            cell.getElement().style.color = '#C0C0C0';
        }
    }
    return cell.getValue();
}

export function tabulatorformatterJiraUserWithAvatar(cell: any, _formatterParams: any, _onRendered: any): any {
    const data = cell.getValue();
    let html = '';
    if (data === null || data === undefined) return html;
    try {
        if (data.avatarUrls['24x24']) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            html += `<img style="border-radius: 50%; float: right; vertical-align: top; margin-right:1px; margin-top:0px" height="21px" width="21px" src="${data.avatarUrls['24x24']}" >`;
        }
        html += `<a href="mailto:${data.emailAddress as string}"><span style="align:left">${data.displayName as string}</span></a>`;
    } catch (e) {}
    return html;
}

export function tabulatorformatterValueWithExternalLink(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    const data = cell.getRow().getData();
    return `
        <a style="float:right; margin-top: 0px; margin-right: 2px;" href="${formatterParams.urlPrefix as string}${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            data[formatterParams.urlField]
        }" target="_blank">
            <i style="" class="bi bi-box-arrow-up-right">
            </i>
        </a>
        <span style="align:left">${value as string}<span>
        `;
}

export function tabulatorformatterassetNoFormatter(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    const data = cell.getRow().getData();
    if (data.assetEndDate != null && new Date(data.assetEndDate) < new Date()) {
        cell.getElement().classList.add('sapDocStatusCanceled');
    }
    return value;
}

export function tabulatorformatterLinkAdvanced(cell: any, formatterParams: any, _onRendered: any): any {
    const data = cell.getValue();
    if (data === null || data === undefined) return;
    let text = 'link';
    if (formatterParams?.label) text = formatterParams?.label;
    const urlPrefix = (formatterParams?.urlPrefix as string) ?? '';
    return `<a href="${urlPrefix}${data as string}" target="_blank">${text}</a>`;
}

export function tabulatorformatterLinkEx(cell: any, formatterParams: any, _onRendered: any): any {
    const data = cell.getValue();
    if (data === null || data === undefined) return;
    let label = formatterParams?.label ?? 'link';
    if (typeof formatterParams?.label === 'function') label = formatterParams?.label(cell) as string;
    const urlPrefix = (formatterParams?.urlPrefix as string) ?? '';

    let url = formatterParams?.url ?? cell.getValue();
    if (typeof formatterParams?.url === 'function') url = formatterParams?.url(cell);

    return `<a href="${urlPrefix}${url as string}" target="_blank">${label as string}</a>`;
}

export function tabulatorformatterIssueStatusWithAvatar(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (!value) return '';

    const url = JiraHelpers.jiraURLPrefix + (value.key as string);
    const bAssignable = value.status !== 'Done' && value.status !== 'On Hold' && value.status !== 'Canceled';
    let html = '';

    if (bAssignable) html += "<input class='ppmcSubTask' type='checkbox' id='" + (value.key as string) + "' />";

    html += '<span><a target="_blank" href="' + url + '">';
    html += JiraHelpers.GetJiraStatusSpan(value.status);
    if (bAssignable) html += JiraHelpers.GetJiraAvatarSpan(value.assignee);
    html += '</a></span>';

    if (value.resolutiondate) {
        const sp = timeSince(new Date(value.resolutiondate));
        if (sp.days < 2) {
            cell.getElement().style.backgroundColor = '#ffff00';
        }
    }
    return html;
}

export function tabulatorformatterPPMCHeader(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    let html = '';
    html += JiraHelpers.GetProductsSpan(formatterParams.products) + '<br/>';
    html += JiraHelpers.GetJiraStatusSpan(formatterParams.status.name) + '<br/>';
    html += JiraHelpers.GetLinkSpan(value, JiraHelpers.jiraURLPrefix + (formatterParams.key as string)) + '<br/>';
    html += formatTimeSpentWork(formatterParams.estTime);
    return html;
}

export function formatTimeSpentWork(valueInSec: number): string {
    try {
        if (valueInSec < 60) return valueInSec.toFixed(0) + ' s';
        if (valueInSec < 60 * 60) return Math.round(valueInSec / 60).toFixed(0) + ' m';
        if (valueInSec < 60 * 60 * 8) return Math.round(valueInSec / (60 * 60)).toFixed(0) + ' h';
        return Math.round(valueInSec / (60 * 60 * 7.5)).toFixed(0) + ' wd';
    } catch (error) {
        return '';
    }
}

export function formatTimeSpent(valueInSec: number): string {
    try {
        if (valueInSec < 60) return valueInSec.toFixed(0) + ' s';
        if (valueInSec < 60 * 60) return Math.round(valueInSec / 60).toFixed(0) + ' m';
        if (valueInSec < 60 * 60 * 24) return Math.round(valueInSec / (60 * 60)).toFixed(0) + ' h';
        return Math.round(valueInSec / (60 * 60 * 24)).toFixed(0) + ' d';
    } catch (error) {
        return '';
    }
}

export function tabulatorformatterTimeSpent(cell: any, formatterParams: any, _onRendered: any): any {
    let value = cell.getValue();
    if (value == null || value === undefined) return '';
    value = Number(value);
    if (formatterParams.fullday === true) return formatTimeSpent(value);
    return formatTimeSpentWork(value);
}

export function tabulatorformatterDangerIfGreaterThenZero(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value > 0) {
        cell.getElement().classList.add('bg-danger');
        cell.getElement().classList.add('text-white');
    }
    return value;
}

export function tabulatorformatterWarningIfZero(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value === 0 || value === undefined || value === null) {
        cell.getElement().classList.add('bg-warning');
    }
    return value;
}

export function tabulatorformatterWarningIfEmpty(cell: any, _formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    if (value === '' || value === undefined || value === null) {
        cell.getElement().classList.add('bg-warning');
    } else {
        cell.getElement().classList.remove('bg-warning');
    }
    return value;
}

export function tabulatorformatterErrorIfEmpty(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell.getValue();
    const suppress = formatterParams?.supress != null && formatterParams?.supress(cell);
    if (!suppress && (value === '' || value === undefined || value === null)) {
        cell.getElement().classList.add('bg-danger');
        cell.getElement().classList.add('white-text');
    } else {
        cell.getElement().classList.remove('bg-danger');
        cell.getElement().classList.remove('white-text');
    }
    return value;
}

export function tabulatorformatterQuantity(cell: any, formatterParams: any, _onRendered: any): any {
    const value = cell?.getValue();
    if (formatterParams?.zeroHighlightClass && value === 0) {
        cell.getElement().classList.add(...formatterParams?.zeroHighlightClass);
    }
    if (formatterParams?.negativeHighlightClass && value < 0) {
        cell.getElement().classList.add(...formatterParams?.negativeHighlightClass);
    }
    if (formatterParams?.positiveHighlightClass && value > 0) {
        cell.getElement().classList.add(...formatterParams?.positiveHighlightClass);
    }
    if (value === 0 && formatterParams?.showZeroAsBlank === true) return null;
    return value;
}

export function tabulatorformatterSapEmployeeObject(cell: Tabulator.CellComponent): string | HTMLElement {
    const value = cell.getValue();
    return `${value.LastName as string} ${value.FirstName as string}`;
}

export function tabulatorFormatterFilament(cell: Tabulator.CellComponent): string | HTMLElement {
    const value = cell.getValue();
    if (value == null) return '';
    const valueLC = value.toLowerCase();

    if (valueLC.includes('white')) {
        cell.getElement().style.backgroundColor = 'white';
        cell.getElement().style.color = 'black';
        return 'WHITE';
    }
    if (valueLC.includes('black')) {
        cell.getElement().style.backgroundColor = 'black';
        cell.getElement().style.color = 'white';
        return 'BLACK';
    }
    if (valueLC.includes('orange')) {
        cell.getElement().style.backgroundColor = 'orange';
        cell.getElement().style.color = 'white';
        return 'ORANGE';
    }
    if (valueLC.includes('transparent')) {
        cell.getElement().style.backgroundColor = 'white';
        cell.getElement().style.color = 'grey';
        return 'TRANS';
    }

    return value;
}
