import React from 'react';
import ReactTabulatorEx from '../../Tabulator/TabulatorEx';
import type { ReactTabulatorOptions } from 'react-tabulator';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export function SparePartsBuffer(): JSX.Element {
    const refTable = React.useRef<any>(null);
    const routeParams = useParams();
    const src = routeParams?.src ?? '';

    const columns: any[] = [
        { field: '#' },
        { field: '$' },
        { field: 'ItemPhoto' },
        { field: 'ItemCode' },
        { field: 'ItemName' },
        { field: 'FirmName' },
        { field: 'MPN' },
        { field: 'MinStock', template: 'Quantity', title: 'Min' },
        {
            field: 'OnHand',
            template: 'Quantity',
            title: 'Qty',
            formatter: function (cell: any, _formatterParams: any, _onRendered: any): any {
                const rowData = cell.getRow().getData();
                const value = cell.getValue();
                if (rowData.MinStock > value) {
                    cell.getElement().classList.add(...['bg-danger', 'text-white']);
                }
                if (rowData.SparePartsMax > value) {
                    cell.getElement().classList.add(...['bg-warning', 'text-white']);
                }
                return value;
            }
        },
        { field: 'MaxStock', template: 'Quantity', title: 'Max' },
        {
            field: 'Refill',
            template: 'Quantity',
            title: 'Refill',
            mutator: function (value: any, data: any) {
                if (data.MinStock === null || data.MinStock === 0) return null;
                if (data.MaxStock === null || data.MaxStock === 0) return null;
                return Math.max(data.MaxStock - data.OnHand, 0);
            },
            formatter: function (cell: any, _formatterParams: any, _onRendered: any): any {
                const value = cell.getValue();
                if (value === null || value === 0) return;
                cell.getElement().classList.add(...['bg-info', 'text-white']);
                return value;
            }
        }
    ];

    const options: ReactTabulatorOptions = {
        ajaxURL: `/api-sap/db/${src}/project-spare-parts-buffer`,
        columns,
        initialSort: [{ column: 'Refill', dir: 'desc' }]
    };

    const onTableRef = (r: any): void => {
        refTable.current = r.current;
    };

    const navigate = useNavigate();

    return (
        <ReactTabulatorEx onRef={onTableRef} options={options} extendedOptions={{}}>
            <Button
                className="me-2 btn-sm btn-secondary "
                onClick={() => {
                    const selectedData = refTable?.current?.getSelectedData();
                    const data = selectedData.map((x: any) => {
                        return {
                            ItemCode: x.ItemCode,
                            ItemDescription: x.ItemName,
                            Quantity: x.Refill,
                            U_SFT_UCEL: 'refill'
                        };
                    });

                    navigate(`/sap/${src}/inventory-transfer-request-form`, {
                        state: {
                            U_PHO_POSubj: `Refill spareparts for SpareParts Buffer`,
                            ToWarehouse: 'BP_STBF',
                            data
                        }
                    });
                }}
            >
                Create Inventory Transfer Request
            </Button>
        </ReactTabulatorEx>
    );
}
