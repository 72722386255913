import { SAPDocumentHeader, SAPDocumentLine } from '../Components/SAP/DocViewer/SAPTypes';
import { useAxiosEx } from '../DataLoader';
import { unFlattenForest } from '../Tabulator/TabulatorEx-helpers';
/*
const fakeData = {
    header: {
        DocEntry: 1,
        DocNum: '1',
        CANCELED: 'N',
        DocStatus: 'O',
        ObjType: 1,
        DocDate: new Date(),
        DocDueDate: new Date(),
        CardCode: '1',
        CardName: 'One',
        NumAtCard: '',
        DocCur: 'EUR',
        DocTotal: 1,
        DocRate: 1
    } as SAPDocumentHeader,
    lines: [
        { DocEntry: 1, LineNum: 1, LineStatus: 'O', ItemCode: 'One', Dscription: '', Quantity: 1, Price: 1, Currency: 'EUR', _children: [] }
    ] as SAPDocumentLine[]
};*/
export function useSAPSalesOrder(src: string, DocNum: string) {
    const url = `/api-sap/db/${src}/sales-order-details?DocNum=${DocNum}`;
    const [{ data, error, loading }, refetch] = useAxiosEx<any[][]>(url);

    /*
    if (process.env.NODE_ENV !== 'production') {
        return {
            data: fakeData
        };
    }*/

    if (!data) {
        return {
            data: undefined,
            error,
            loading,
            refetch
        };
    }

    const lines = unFlattenForest(data[1]);

    return {
        data: {
            header: data[0][0] as SAPDocumentHeader,
            lines: lines as SAPDocumentLine[]
        },
        error,
        loading,
        refetch
    };
}
