import { DropEvent, DropzoneOptions, useDropzone } from 'react-dropzone';
import React from 'react';
import { withFormController } from '../HOC/withFormController';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'top',
    justifyContent: 'top',
    padding: '8px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    outline: 'none',
    height: '100%',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676',
    color: '#00e676'
};

const disabledStyle = {
    color: '#bdbdbd'
};

const enabledStyle = {
    color: 'black'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const invalidStyle = {
    borderColor: 'red',
    color: 'red'
};

const validStyle = {
    borderColor: 'green',
    color: 'green'
};

interface DropzoneProps extends Omit<DropzoneOptions, 'OnDropAccepted'> {
    subject: string;
    minFiles?: number;
    value?: File[];
    onChangeForm?: (files: File[], event: DropEvent) => void;
}

export default function Dropzone({ subject, minFiles, onChangeForm, disabled, value, ...rest }: DropzoneProps) {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive } = useDropzone({
        ...rest,
        disabled,
        onDropAccepted: (files: File[], event: DropEvent) => {
            setFiles(files);
            onChangeForm?.(files, event);
        }
    });
    const [files, setFiles] = React.useState<File[]>(value ?? []);
    const isValid = minFiles && files.length >= minFiles;

    const style: any = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(isValid === false && !disabled && !isDragActive ? invalidStyle : {}),
            ...(isValid === true && !disabled && !isDragActive ? validStyle : {}),
            ...(disabled && !isDragActive ? disabledStyle : {}),
            ...(!disabled && !isDragActive ? enabledStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject, isValid, disabled, isDragActive]
    );

    const DRAG = "Drag'n'drop files or click to select";
    const DISABLED = 'Not Required';
    const WARNING = `Min ${minFiles} file required`;
    const CLEAR = 'Click to clear ...';

    return (
        <div style={{ height: '100%' }}>
            <div {...getRootProps({ style })}>
                <h6 className="text-center">{subject}</h6>
                <input {...getInputProps()} />
                <span className="text-center ">{rest.maxFiles && files.length < rest.maxFiles && !disabled ? DRAG : disabled ? DISABLED : null}</span>
                {files.map((x, i) => (
                    <span className="text-center" key={i}>
                        {x.name}
                    </span>
                ))}
                {files.length > 0 && <span className="text-center text-muted">{CLEAR}</span>}
                {isValid === false && <span className="text-center text-muted">{WARNING}</span>}
            </div>
        </div>
    );
}

export const DropzoneFormControl = withFormController(Dropzone);
