export const userAccessListPPMC = [
    'janickovic@brightpick.ai',
    'prielozny@brightpick.ai',
    'kratky@brightpick.ai',
    'zhuk@brightpick.ai',
    'ravas@brightpick.ai',
    'furster@brightpick.ai',
    'balhar@brightpick.ai',
    'celinsky@brightpick.ai',
    'fedor@brightpick.ai',
    'klepochova@brightpick.ai',
    'mudrikova@brightpick.ai'
];
export const userAccessListPCP = userAccessListPPMC;

export const jiraBaseURL = 'https://photoneo.atlassian.net/';
