import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
/*
interface Option {
    label: string;
    sublabel: string;
    link: string;
    image: string;
    searchResult: string;
    keywords: string[];
}
*/
export function SearchBar({ searchCache }: { searchCache: any[] }): JSX.Element {
    const inputRef = React.useRef<any>(null);
    const navigate = useNavigate();
    useHotkeys('ctrl+/', () => inputRef.current?.focus(), [inputRef]);

    const filterBy = (option: any, props: any) => {
        const searchWords = splitWords(props.text);
        return searchWords.every((sw) => option.keywords.some((kw: string) => kw.includes(sw)));
    };

    const splitWords = (text: string) =>
        text
            .toLowerCase()
            .split(' ')
            .map((x) => x.trim())
            .filter((x) => x.length > 0 && x !== null);

    return (
        <Typeahead
            ref={inputRef}
            clearButton
            align="right"
            labelKey={'label'}
            id="async-example"
            filterBy={filterBy}
            isLoading={false}
            autoFocus
            minLength={3}
            maxResults={20}
            onChange={(selected: any) => {
                if (selected.length > 0) {
                    inputRef.current?.clear();
                    navigate(selected[0].link);
                }
            }}
            inputProps={{ 'aria-label': 'Search', spellCheck: 'false' }}
            options={searchCache}
            placeholder='Search...    ctrl+"/"'
            renderMenuItemChildren={(option: any, { text }) => {
                const searchWords = splitWords(text);
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%'
                        }}
                    >
                        <img src={option.image} alt="" style={{ width: '48px', height: '48px', marginRight: '10px' }} />
                        <div>
                            <div>
                                <Highlighter highlightClassName="highlighterSearch" searchWords={searchWords} textToHighlight={option.label} />
                            </div>
                            <small className="text-muted">
                                {typeof option.sublabel === 'string' ? (
                                    <Highlighter highlightClassName="highlighterSearch" searchWords={searchWords} textToHighlight={option.sublabel} />
                                ) : (
                                    option.sublabel
                                )}
                            </small>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <small className="text-muted">{option.searchResult}</small>
                        </div>
                    </div>
                );
            }}
        />
    );
}
