import { Badge, Button } from 'react-bootstrap';
import { useDHLTracking } from '../../Hooks/useDHLTracking';
import { useInterval } from '../../Hooks/useInterval';
import React from 'react';
import { DateTime } from 'luxon';

interface TrackingProps {
    trackingId?: string;
    autoRefreshMinutes?: number;
}

export function ShipmentTrackerChip(props: TrackingProps) {
    const [{ data, loading }, refresh] = useDHLTracking(props.trackingId ?? '');
    const shipment = (data?.shipments?.length ?? 0) > 0 ? data?.shipments?.[0] : undefined;

    useInterval(
        () => {
            refresh();
        },
        1000 * 60 * (props.autoRefreshMinutes ?? 1000)
    );

    const status = React.useMemo(() => {
        if (!shipment) {
            return { lastEvent: undefined, isDelivered: undefined, variant: 'secondary' };
        }
        const lastEvent = shipment.events.length > 0 ? shipment.events[shipment.events.length - 1] : undefined;
        const isDelivered = shipment.events.some((event) => event.typeCode === 'OK');
        const variant = isDelivered ? 'success' : 'primary';
        const eta = DateTime.fromISO(shipment.estimatedDeliveryDate);

        return { lastEvent, isDelivered, variant, eta: isDelivered ? undefined : '- ETA: ' + eta.toRelative() };
    }, [shipment]);

    if (!shipment) {
        return <Badge bg="secondary">No tracking info</Badge>;
    }

    if (loading && !data) {
        return <Badge bg="secondary">Loading ...</Badge>;
    }

    return (
        <Badge
            as={Button}
            bg={status.variant}
            onClick={() => {
                window.open(`https://www.dhl.com/en/express/tracking.html?AWB=${shipment.shipmentTrackingNumber}`);
            }}
        >
            DHL {shipment.shipmentTrackingNumber} - {status.lastEvent?.description}
            {status.eta}
        </Badge>
    );
}
