import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import NavDropdown from 'react-bootstrap/NavDropdown';
import classNames from 'classnames';
import MenuItemsBrightpick from './MenuItemsBrightpick';
import MenuItemsPhotoneo from './MenuItemsPhotoneo';
import { Spinner } from 'react-bootstrap';
import { useAxiosLoader } from '../DataLoader';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import SAPMenuItems from './SAPMenu';
import { SearchBar } from '../Components/SearchBar';
import { useFrontendInfo } from '../Hooks/useFrontendInfo';
// import { useApprovalPendingCount } from '../Hooks/useApprovalPendingCount';
import { NavLink } from 'react-router-dom';
import { useSearchCache } from '../Hooks/useSearchCache';
import { AuthenticatedUser } from '@bp/utils';

export default function Menu({ user }: { user: AuthenticatedUser | null }): JSX.Element {
    const isLoading = useAxiosLoader();
    const [frontendData] = useFrontendInfo(user);
    const [{ data: searchCache }] = useSearchCache(user);
    const isBrightpick = user?.tenant === 'brightpick.ai';
    const sapPendingApprovals = 0; // useApprovalPendingCount();

    return (
        <Navbar
            collapseOnSelect
            sticky="top"
            expand="xl"
            variant="dark"
            className={classNames('p-3', 'py-2', 'fixed-top', {
                BPnavbar: user?.tenant === 'brightpick.ai',
                PHnavbar: user?.tenant === 'photoneo.com'
            })}
        >
            <Navbar.Brand as={Link} to="/project/board">
                {user?.tenant === 'brightpick.ai' && (
                    <div>
                        <span className="BPLogoFont BPLogoColorRed d-none d-sm-inline">BRIGHTP</span>
                        <span className="BPLogoFont BPLogoColorWhite d-none d-sm-inline">1</span>
                        <span className="BPLogoFont BPLogoColorRed d-none d-sm-inline">CK</span>
                        <span className="BPLogoFont BPLogoColorRed d-sm-none">1</span>
                        <span className="px-2 BPLogoColorWhite">ops dashboard</span>
                    </div>
                )}
                {user?.tenant === 'photoneo.com' && (
                    <div>
                        <Image src="/ph_logo.png" alt="UserName profile image" style={{ height: '35px', padding: '0px' }} />
                    </div>
                )}
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {user?.tenant === 'brightpick.ai' && <MenuItemsBrightpick frontendData={frontendData} user={user} />}
                    {user?.tenant === 'photoneo.com' && <MenuItemsPhotoneo />}
                    <SAPMenuItems dbName={'live'} isBrightpick={isBrightpick} eventKeyOffset={200} />
                    {/* <SAPMenuItems dbName={'test'} isBrightpick={isBrightpick} eventKeyOffset={300} /> */}
                </Nav>
                <Nav>
                    <Nav.Item style={{ display: 'flex', alignItems: 'center' }}>
                        <iframe title="systemstatus" src="https://bpdashboard.betteruptime.com/badge?theme=dark" width="250" height="30"></iframe>
                    </Nav.Item>
                    <SearchBar searchCache={searchCache} />
                </Nav>
            </Navbar.Collapse>
            <Nav className="flex-row justify-content-end float-right me-0">
                {isLoading && (
                    <Nav.Link as={Link} to={''}>
                        <Spinner className="me-2" animation="grow" size="sm" variant="light"></Spinner>
                    </Nav.Link>
                )}
                {!isLoading && <div style={{ width: '40px' }}></div>}
                {sapPendingApprovals > 0 && (
                    <Nav.Link as={Link} to={'/sap/live/approval-pending'} className="me-2">
                        <Badge bg="danger">SAP Approvals: {sapPendingApprovals}</Badge>
                    </Nav.Link>
                )}

                {user !== null && (
                    <Navbar.Collapse>
                        <NavDropdown
                            align="end"
                            title={
                                <div style={{ display: 'inline-block' }}>
                                    <Image
                                        src={user?.picture}
                                        alt="UserName profile image"
                                        referrerPolicy="no-referrer"
                                        roundedCircle
                                        style={{ height: '40px', padding: '0px' }}
                                    />
                                </div>
                            }
                            className="AppLoggedUser"
                        >
                            <NavDropdown.Header>{user.name}</NavDropdown.Header>
                            <NavDropdown.Item as={NavLink} eventKey={901} to="/logout">
                                Logout
                            </NavDropdown.Item>
                            <NavDropdown.Item as={NavLink} eventKey={902} to="/about">
                                About
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={NavLink} eventKey={903} to="https://bpdashboard.betteruptime.com/">
                                System Status
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                )}
            </Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Navbar>
    );
}
