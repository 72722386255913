import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { SparePartsBuffer } from '../Components/SAP/SparePartsBuffer';
import { SAPDocument } from '../Components/SAP/DocViewer/SAPDocument';
import { SAPSalesOrders } from '../Components/SAP/DocViewer/SAPDocumentList';
import PurchaseRequestForm2 from '../Forms/PurchaseRequestForm2';
import SparePartsReceiveForm from '../Forms/SparePartsReceiveForm';

const ProductList = React.lazy(async () => await import('../Views/Products/ProductTree'));
const ProductCompatibilityMatrix = React.lazy(async () => await import('../Views/Products/ProductCompatibilitymatrix'));
const ProductRunners = React.lazy(async () => await import('../Views/Products/ProductRunners'));
const ProductAmrs = React.lazy(async () => await import('../Views/Products/ProductAmrs'));
const ProductAddons = React.lazy(async () => await import('../Views/Products/ProductAddons'));
const ProductPickers = React.lazy(async () => await import('../Views/Products/ProductPickers'));
const ProductChargingDocks = React.lazy(async () => await import('../Views/Products/ProductChargingDocks'));
const ProductIntuitions = React.lazy(async () => await import('../Views/Products/ProductIntuitions'));
const ProductPage = React.lazy(async () => await import('../Components/Product/ProductPage'));

const ProjectList = React.lazy(async () => await import('../Views/Projects/ProjectList'));
const ProjectBoard = React.lazy(async () => await import('../Views/Projects/ProjectBoard'));
const ProjectMap = React.lazy(async () => await import('../Views/Projects/ProjectMap'));
const ProjectCharts = React.lazy(async () => await import('../Views/Projects/ProjectCharts'));
const ProjectDetail = React.lazy(async () => await import('../Views/Projects/ProjectDetail'));

const ApprovalRequests = React.lazy(async () => await import('../Views/SAP/ApprovalRequests'));
const ApprovalPending = React.lazy(async () => await import('../Views/SAP/ApprovalPending'));

const PurchaseRequests = React.lazy(async () => await import('../Views/SAP/PurchaseRequestsRecent'));
const CostCenterList = React.lazy(async () => await import('../Views/SAP/CostCenterList'));
const PurchaseOrdersRecent = React.lazy(async () => await import('../Views/SAP/PurchaseOrdersRecent'));
const PurchaseAdvanceInvoicesRecent = React.lazy(async () => await import('../Views/SAP/PurchaseAdvanceInvoicesRecent'));
const SalesOrdersRecent = React.lazy(async () => await import('../Views/SAP/SalesOrdersRecent'));
const ManufacturingOrdersRecent = React.lazy(async () => await import('../Views/SAP/ManufacturingOrdersRecent'));
const ManufacturingOrdersClosed = React.lazy(async () => await import('../Views/SAP/ManufacturingOrdersClosed'));
const InventoryTransferRequestsTabulator = React.lazy(async () => await import('../Components/SAP/InventoryTransferRequests'));

const MasterDataTree = React.lazy(async () => await import('../Components/SAP/MasterDataTree'));
const MasterDataFlat = React.lazy(async () => await import('../Components/SAP/MasterDataFlat'));
const ThreeDPrintsTracker = React.lazy(async () => await import('../Views/SAP/ThreeDPrintsTracker'));
const ManufacturingTracker = React.lazy(async () => await import('../Views/SAP/ManufacturingTracker'));
const ProcurementTracker = React.lazy(async () => await import('../Views/SAP/ProcurementTracker'));
const ItemCodeBomTracer = React.lazy(async () => await import('../Views/SAP/ItemCodeBomTracer'));
const StockBins = React.lazy(async () => await import('../Views/SAP/StockBins'));
const ManufacturersList = React.lazy(async () => await import('../Views/SAP/ManufacturersList'));
const FinancialAssets = React.lazy(async () => await import('../Components/SAP/FinancialAssets'));

const PurchaseRequestForm = React.lazy(async () => await import('../Forms/PurchaseRequestForm'));
const InventoryTransferRequestForm = React.lazy(async () => await import('../Forms/InventoryTransferRequestForm'));
const SparePartsConsumeForm = React.lazy(async () => await import('../Forms/SparePartsConsumeForm'));
const SparePartsRequestForm = React.lazy(async () => await import('../Forms/SparePartsRequestForm'));

const MasterMatrix = React.lazy(async () => await import('../Views/MasterMatrix/Matrix'));
const MasterMatrixManager = React.lazy(async () => await import('../Views/MasterMatrix/Manager'));
const MasterMatrixChart = React.lazy(async () => await import('../Views/MasterMatrix/Chart'));

const About = React.lazy(async () => await import('../Views/About'));
const Test = React.lazy(async () => await import('../Views/Test'));
const ShiftManager = React.lazy(async () => await import('../Views/ShiftManager/shift-manager'));

const routes = [
    { path: '/', element: ProjectBoard },
    { path: '/about', element: About },
    { path: '/test', element: Test },
    { path: '/app-shift-manager/:shiftGroupId', element: ShiftManager },

    { path: '/product/list', element: ProductList },
    { path: '/product/compatibility-matrix', element: ProductCompatibilityMatrix },
    { path: '/product/runners', element: ProductRunners },
    { path: '/product/amrs', element: ProductAmrs },
    { path: '/product/addons', element: ProductAddons },
    { path: '/product/pickers', element: ProductPickers },
    { path: '/product/charging-docks', element: ProductChargingDocks },
    { path: '/product/intuitions', element: ProductIntuitions },
    { path: '/product/detail/:serialNumber', element: ProductPage, props: { type: 'serialNumber' } },

    { path: '/project/list', element: ProjectList },
    { path: '/project/board', element: ProjectBoard },
    { path: '/project/map', element: ProjectMap },
    { path: '/project/charts/:type', element: ProjectCharts },

    { path: '/project/:projectCode/', element: ProjectDetail },
    { path: '/project/:projectCode/:tabId', element: ProjectDetail },
    { path: '/project/:projectCode/:tabId/:docId', element: ProjectDetail },

    { path: '/mm/:project/matrix/:type', element: MasterMatrix },
    { path: '/mm/:project/manager', element: MasterMatrixManager },
    { path: '/mm/:project/charts', element: MasterMatrixChart },

    { path: '/sap/:src/approval-requests', element: ApprovalRequests },
    { path: '/sap/:src/approval-pending', element: ApprovalPending },
    { path: '/sap/:src/approval-pending/:wddCode', element: ApprovalPending },
    { path: '/sap/:src/purchase-requests', element: PurchaseRequests },
    { path: '/sap/:src/cost-centers', element: CostCenterList },
    { path: '/sap/:src/manufacturers', element: ManufacturersList },
    { path: '/sap/:src/purchase-orders-recent', element: PurchaseOrdersRecent },
    { path: '/sap/:src/purchase-advanceinvoices-recent', element: PurchaseAdvanceInvoicesRecent },
    { path: '/sap/:src/sales-orders-recent', element: SalesOrdersRecent },
    { path: '/sap/:src/manufacturing-orders-recent', element: ManufacturingOrdersRecent },
    { path: '/sap/:src/manufacturing-orders-closed', element: ManufacturingOrdersClosed },
    { path: '/sap/:src/inventory-transfer-requests-recent', element: InventoryTransferRequestsTabulator },
    { path: '/sap/:src/master-data-tree', element: MasterDataTree },
    { path: '/sap/:src/master-data-flat', element: MasterDataFlat },
    { path: '/sap/:src/3d-prints-tracker', element: ThreeDPrintsTracker },
    { path: '/sap/:src/manufacturing-tracker', element: ManufacturingTracker },
    { path: '/sap/:src/procurement-tracker', element: ProcurementTracker },
    { path: '/sap/:src/itemcode-bom-tracer', element: ItemCodeBomTracer },
    { path: '/sap/:src/stock-bins', element: StockBins },
    { path: '/sap/:src/financial-assets', element: FinancialAssets, props: {} },

    { path: '/sap/:src/purchase-request-form', element: PurchaseRequestForm },
    { path: '/sap/:src/purchase-request-form2', element: PurchaseRequestForm2 },
    { path: '/sap/:src/inventory-transfer-request-form', element: InventoryTransferRequestForm },
    { path: '/sap/:src/spare-parts-buffer', element: SparePartsBuffer },
    { path: '/sap/:src/test', element: SAPDocument },
    { path: '/sap/:src/test2', element: SAPSalesOrders },

    { path: '/spare-parts-request-form', element: SparePartsRequestForm },
    { path: '/spare-parts-consume-form', element: SparePartsConsumeForm },
    { path: '/spare-parts-receive-form', element: SparePartsReceiveForm }
];

const Content = (props: any): JSX.Element => {
    return (
        <Suspense fallback={<Spinner color="primary" />}>
            <Routes>
                {routes.map((route, idx) => {
                    return <Route key={idx} path={route.path} element={<route.element {...props} {...route.props} />} />;
                })}
            </Routes>
        </Suspense>
    );
};

export default React.memo(Content);
